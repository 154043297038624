import { render, staticRenderFns } from "./TaskRight.vue?vue&type=template&id=7e8c41e0&scoped=true&"
import script from "./TaskRight.vue?vue&type=script&lang=js&"
export * from "./TaskRight.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e8c41e0",
  null
  
)

export default component.exports
<template>
  <LayoutBox
    :loading="loading"
    :title="'任务待完成一览('+total+')'"
    style="
      cursor: pointer;
      transform: rotateY(-6deg) scaleX(1.3) translateY(20px);
      transform-origin: right center;
    "
    @onTitleClick='titleClick'
  >
    <ScrollTable
      ref="ScrollTable"
      :key="refreshKey"
      v-model="loading"
      :columns="getColumns"
      @getTotal="e=> total = e"
      is-global-query
      :query="{
        statusList: '01,02,04,05',
        punishFlag: '02',
      }"
      :url="`/${$businessISG}/monitor/home/task-page`"
      @clickRow="({ row }) => $refs.TaskInfoDialog.openDialog({ row })"
    ></ScrollTable>
    <TaskInfoDialog
      ref="TaskInfoDialog"
      @success="$refs.ScrollTable && $refs.ScrollTable.refresh()"
    ></TaskInfoDialog>
    <AllTaskInfoDialog ref="AllTaskInfoDialog"></AllTaskInfoDialog>
  </LayoutBox>
</template>

<script>
import { mapState } from 'vuex';
import TaskInfoDialog from './TaskInfoDialog.vue';
import AllTaskInfoDialog from '@/views/screen/earlyWarning/modules/AllTaskInfoDialog';
export default {
  //组件注册
  components: {
    LayoutBox: () => import('../components/LayoutBox.vue'),
    ScrollTable: () => import('@/views/screen/components/ScrollTable'),
    TaskInfoDialog,
    AllTaskInfoDialog
  },
  //组件传值
  props: {
    refreshKey: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      total:0
    };
  },
  //计算属性
  computed: {
    ...mapState({
      isSubbranch: (state) => state.screenStore.isSubbranch,
    }),
    getColumns() {
      return [
        {
          title: '机构',
          field: 'organizationText',
          width: '20%',
          visible: !this.isSubbranch,
        },
        {
          title: '计划完成时间',
          field: 'planCompleteTime',
          width: `${this.isSubbranch ? 50 : 40}%`,
          visible: true,
        },
        {
          title: '任务类型',
          field: 'analysisItemText',
          width: `${this.isSubbranch ? 25 : 20}%`,
          visible: true,
        },
        {
          title: '任务状态',
          field: 'statusText',
          width: `${this.isSubbranch ? 25 : 20}%`,
          visible: true,
        },
      ].filter((e) => e.visible);
    },
  },
  //属性监听
  watch: {},
  //DOM访问
  mounted() {},
  //保存方法
  methods: {
    titleClick(){
      console.log(1);
      this.$refs.AllTaskInfoDialog.openDialog()
    },
  },
};
</script>

<style lang="scss" scoped></style>

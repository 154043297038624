var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LayoutBox',{staticStyle:{"cursor":"pointer","transform":"rotateY(-6deg) scaleX(1.3) translateY(20px)","transform-origin":"right center"},attrs:{"loading":_vm.loading,"title":'任务待完成一览('+_vm.total+')'},on:{"onTitleClick":_vm.titleClick}},[_c('ScrollTable',{key:_vm.refreshKey,ref:"ScrollTable",attrs:{"columns":_vm.getColumns,"is-global-query":"","query":{
      statusList: '01,02,04,05',
      punishFlag: '02',
    },"url":("/" + _vm.$businessISG + "/monitor/home/task-page")},on:{"getTotal":function (e){ return _vm.total = e; },"clickRow":function (ref) {
        var row = ref.row;

        return _vm.$refs.TaskInfoDialog.openDialog({ row: row });
}},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}}),_c('TaskInfoDialog',{ref:"TaskInfoDialog",on:{"success":function($event){_vm.$refs.ScrollTable && _vm.$refs.ScrollTable.refresh()}}}),_c('AllTaskInfoDialog',{ref:"AllTaskInfoDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }